import React, {useCallback} from "react"
import JobHero from "../JobHero"
import {BulletIcon} from '../../images/icons'
import {Heading, List} from "../UI/Typography"
import Dash from "../UI/Dash"
import Form from "../Form"
import {FormattedMessage} from "react-intl"
import DropBg from '../UI/DropBg'
import {ThemeProvider} from "styled-components"
import dark from "../../theme/dark"
import FullWidthMessage from "../FullWidthMessage"
import DropBlurVioletBg from "../../images/jobs/DropBlurVioletBg.svg"
import * as Yup from "yup"
import {Fade} from 'react-reveal'
import {formEndpoints} from "../../utilities/constants"

const SingleJobPage = ({IntlTextObj, intl}) => {
    const {
        role,
        roleValue,
        skills,
        hard_skills_title,
        hard_skills,
        soft_skills_title,
        soft_skills,
        adventure_title_thin,
        adventure_title,
        benefits_title,
        benefits,
        offer_title,
        offer
    } = IntlTextObj

    const formFields = [
        {
            name: 'name',
            label: 'jobs.form_name',
            required: true,
            type: 'input-text',
        },
        {
            name: 'surname',
            label: 'jobs.form_lastname',
            required: true,
            type: 'input-text',
        },
        {
            name: 'email',
            label: 'jobs.form_email',
            required: true,
            type: 'input-email',
        },
        {
            name: 'phone',
            label: 'jobs.form_phone',
            required: true,
            type: 'input-text',
        },
        {
            name: 'city',
            label: 'jobs.form_city',
            type: 'input-text',
        },
        {
            name: 'fileCVUrl',
            label: 'jobs.form_cv',
            required: true,
            type: 'dropzone',
        },
        {
            name: 'filePortfolioUrl',
            label: 'jobs.form_portfolio',
            type: 'dropzone',
        },
        {
            name: 'portfolioUrl',
            label: 'jobs.form_url_portfolio',
            type: 'input-text',
        },
        {
            name: 'linkedinUrl',
            label: 'jobs.form_linkedin',
            type: 'input-text',
        },
        {
            name: 'languages',
            label: 'jobs.form_languages',
            required: true,
            type: 'input-text',
        },
        {
            name: 'origin',
            label: 'jobs.form_job',
            type: 'input-text',
        },
        {
            name: 'startAt',
            label: 'jobs.form_start',
            type: 'input-text',
        },
        {
            name: 'privacy',
            label: 'jobs.form_personal_data',
            required: true,
            type: 'checkbox',
        }
    ]

    const validationSchema = Yup.object({
        name: Yup.string().required(intl.formatMessage({id: 'contactus.error_required'})),
        surname: Yup.string().required(intl.formatMessage({id: 'contactus.error_required'})),
        email: Yup.string().email(intl.formatMessage({id: 'contactus.error_bad_email'})).required(intl.formatMessage({id: 'contactus.error_required'})),
        phone: Yup.string().required(intl.formatMessage({id: 'contactus.error_required'})),
        city: Yup.string(),
        fileCVUrl: Yup.object().nullable().required(intl.formatMessage({id: 'contactus.error_required'})),
        linkedinUrl: Yup.string().url(intl.formatMessage({id: 'contactus.error_bad_url'})),
        languages: Yup.string().required(intl.formatMessage({id: 'contactus.error_required'})),
        origin: Yup.string(),
        startAt: Yup.string(),
        privacy: Yup.boolean().required(intl.formatMessage({id: 'contactus.error_required'})).oneOf([true], intl.formatMessage({id: 'contactus.error_required'}))
    })

    const formDataToSend = useCallback((values) => ({
        ...values,
        fileCVUrl: values?.fileCVUrl?.url,
        filePortfolioUrl: values?.filePortfolioUrl?.url,
        role: roleValue,
        from: location.href
    }), [])

    return <>
        <JobHero title={role} skills={skills}/>
        <ThemeProvider theme={dark} colors={dark ? dark.colors : null}>
            <div className="section-inner-padding section-medium-dark">
                <div className="container">
                    <div className="row">
                        {hard_skills && (
                            <div className="col-lg-6  col-md-12 col-12 ">
                                <ul className="skills">
                                    <Heading level={"4"}>
                                        <FormattedMessage id={hard_skills_title}/>
                                    </Heading>
                                    {hard_skills.map((val, i) => (
                                        <List key={i}>
                                            <BulletIcon/>
                                            <div>
                                                <FormattedMessage id={val}/>
                                            </div>
                                        </List>
                                    ))}
                                </ul>
                            </div>
                        )}
                        {soft_skills && (
                            <div className="col-lg-6  col-md-12 col-12">
                                <ul className="skills">
                                    <Heading level={"4"}>
                                        <FormattedMessage id={soft_skills_title}/>
                                    </Heading>
                                    {soft_skills.map((val, i) => (
                                        <List key={i}>
                                            <BulletIcon/>
                                            <div>
                                                <FormattedMessage id={val}/>
                                            </div>
                                        </List>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </ThemeProvider>
        <div className="section-inner-padding-top section-padding-bottom">
            <div className="container">
                <div className="row">
                    <div className="col-12 text-center">
                        <Heading level={'2'} thinChild={2}>
                            <FormattedMessage id={adventure_title}/>
                            <FormattedMessage id={adventure_title_thin}/>
                        </Heading>
                        <Dash margin={'24px auto'}/>
                    </div>
                </div>
                <div className="row mt-4">
                    {benefits && (
                        <div className="col-lg-6  col-md-12 col-12">
                            <ul className="skills">
                                <Heading level={"4"}>
                                    <FormattedMessage id={benefits_title}/>
                                </Heading>
                                {benefits.map((val, i) => (
                                    <List key={i}>
                                        <BulletIcon/>
                                        <div>
                                            <FormattedMessage id={val}/>
                                        </div>
                                    </List>
                                ))}
                            </ul>
                        </div>
                    )}
                    {offer && (
                        <div className="col-lg-6  col-md-12 col-12">
                            <ul className="skills">
                                <Heading level={"4"}>
                                    <FormattedMessage id={offer_title}/>
                                </Heading>
                                {offer.map((val, i) => (
                                    <List key={i}>
                                        <BulletIcon/>
                                        <div>
                                            <FormattedMessage id={val}/>
                                        </div>
                                    </List>
                                ))}
                            </ul>
                        </div>
                    )}
                </div>
            </div>
        </div>
        <div className="section-gray">
            <DropBg src={DropBlurVioletBg} top={"0%"} hideOnTablet={true} noOverflow={true}/>
            <div className="section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center">
                            <Heading level={'2'}>
                                <FormattedMessage id={role}/>
                            </Heading>
                            <Dash margin={'24px auto'}/>
                            {/*<span className="job-form-subtitle"><FormattedMessage id="jobs.login_linkedin"/></span>*/}
                        </div>
                        {/*<div className="col-12 d-flex justify-content-center">
                            <LinkedinButton/>
                        </div>*/}
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <Form
                                formFields={formFields}
                                ctaText="jobs.btn_action"
                                validationSchema={validationSchema}
                                endpoint={formEndpoints.CONTACT_US_CV}
                                successMessage="jobs.form_submit_success_text"
                                successTitle="contactus.form_submit_success"
                                formDataToSend={formDataToSend}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <FullWidthMessage title={"jobs.banner_title"} text1={"jobs.banner_message"} ctaLink={"/internships/"}
                          intl={intl} ctaText={"jobs.banner_link"} blur={true}/>
    </>
};

export default SingleJobPage;
