import Colors from "./_colors";
import { Typography } from "./_typography";
import Boxes from "./_boxes";
import Buttons from "./_buttons";
import Images from "./_images";

const fontFamilies = {
    georgia: "georgia, serif",
};

export const theme = {
    // Colors
    colors: { ...Colors },
    primary: Colors.violetDarken200,
    secondary: Colors.white,

    //Paragraph colors
    paragraphPrimary: Colors.violetDarken200,

    // Typography
    boldWeight: "700",
    normalWeight: "400",
    italicStyle: "italic",
    normalStyle: "normal",
    heading: { ...Typography.heading },
    paragraph: { ...Typography.paragraph },
    label: { ...Typography.label },
    anchor: { ...Typography.anchor },
    list: { ...Typography.list },
    // Buttons
    button: { ...Buttons.type },

    // Boxes
    box: { ...Boxes },

    // Image
    image: { ...Images },
};

export default theme;
