import React from "react";
import TransparentDrop from "../../images/transparent_drop.png";
import DropBg from "../UI/DropBg";
import {FormattedMessage} from "react-intl";
import Link from "../Link";
import {Button} from "../UI/Button";

const FullWidthMessage = ({isLight = false, color = 'pink', title, text1, text2, ctaText, ctaLink, blur, intl, ...props}) => {

    return <div className={`FullWidthMessage ${isLight ? 'FullWidthMessage-light' : 'FullWidthMessage-dark'}`}>
        <div className={`color-${color}`} {...props}>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-md-7 col-12 mb-4 mb-md-0">
                        <h3 className={`${text1 ? 'mb-2' : ''}`}>
                            <FormattedMessage id={title} />
                        </h3>
                        {text1 && <p>
                            <FormattedMessage id={text1}/>
                        </p>}
                        {text2 && <p>
                            <FormattedMessage id={text2}/>
                        </p>}
                    </div>
                    <div className="col-md-5 col-12 d-flex justify-content-lg-end justify-content-md-center justify-content-center">
                        <Link className="link-no-decoration" to={ctaLink}>
                            <Button arrow type={isLight ? 'primary' : 'whiteFilled'} label={intl.formatMessage({id: ctaText})}/>
                        </Link>
                    </div>
                </div>
            </div>
            {!isLight && <DropBg src={TransparentDrop} right={'-8%'} bottom={'-50%'} mRight={'-90%'} zIndex={'-1'} blur={blur}/>}
        </div>
    </div>
};

export default FullWidthMessage;
