import React from "react";
import SingleJobPage from "../../components/SingleJobPage";
import withLayout from "../../layout";
import {injectIntl} from "react-intl";
import SEO from "../../components/SEO";
/**
 * note that the key we defined in role is needed for building the SingleJobPage
 * component with the right translation strings and media
 * @returns { SingleJobPage }
 * @constructor
 */

const SingleJobPageWrapper = ({intl}) => {
    const IntlTextObj = {
        role: "backend.junior.title",
        roleValue: "Back-end JR",
        skills: {
            experience_title: "backend.experience_title",
            experience: "backend.junior.experience",
            employment_title: "backend.employment_title",
            employment: "backend.employment",
            placeofwork_title: "backend.placeofwork_title",
            placeofwork: "backend.placeofwork",
            title_left: "backend.title_left",
            text_left: "backend.text_left",
            title_right: "backend.title_right",
            text_right: "backend.text_right",
            text_center: "backend.text_center",
        },
        hard_skills_title: "backend.hard_skills_title",
        hard_skills: ["backend.junior.hard_skill_1", "backend.junior.hard_skill_2", "backend.junior.hard_skill_3", "backend.junior.hard_skill_4"],
        soft_skills_title: "backend.soft_skills_title",
        soft_skills: ["backend.junior.soft_skills_1", "backend.junior.soft_skills_2", "backend.junior.soft_skills_3", "backend.junior.soft_skills_4", "backend.junior.soft_skills_5", "backend.junior.soft_skills_6"],
        adventure_title_thin: "backend.adventure_title_thin",
        adventure_title: "backend.adventure_title",
        benefits_title: "backend.benefits_title",
        benefits: ["backend.benefit_1", "backend.benefit_2", "backend.benefit_3", "backend.benefit_4", "backend.benefit_5"],
        offer_title: "backend.offer_title",
        offer: ["backend.offer_1", "backend.offer_2", "backend.offer_3", "backend.offer_4", "backend.offer_5"],
    };
    const keywords = intl.formatMessage({id:'backend.meta_keywords'}).split(',') || [];

    return (
        <>
            <SEO title={intl.formatMessage({id: 'backend.meta_title'})} description={intl.formatMessage({id: 'backend.meta_description'})} keywords={keywords} lang={intl.locale}/>
            <SingleJobPage IntlTextObj={IntlTextObj} intl={intl}/>
            {/* <SingleJobPage IntlTextObj /> */}
        </>
    );
};

const customProps = {
    localeKey: "backend", // same as file name in src/i18n/translations/your-lang/index.js
};

export default withLayout(customProps)(injectIntl(SingleJobPageWrapper));
