import React from 'react';
import {ThemeProvider} from 'styled-components';
import Header from '../Header';
import {FormattedMessage} from "react-intl";
import light from "../../theme/light";
import JobHeroDrop from '../../images/jobs/JobHeroDrop.png';
import JobHeroDropS from '../../images/jobs/JobHeroDropS.svg';
import DropBg from '../UI/DropBg';
import {Heading, Paragraph} from '../UI/Typography';
import Dash from "../UI/Dash";
import useDimensions from "react-use-dimensions";
import Fade from "react-reveal/Fade.js";
import Link from "../Link";
import {ArrowIcon} from "../../images/icons";

const JobHero = ({title, skills}) => {
    const [ref, {width}] = useDimensions();
    const {
        experience_title,
        experience,
        employment_title,
        employment,
        placeofwork_title,
        placeofwork,
        title_left,
        text_left,
        title_right,
        text_right,
        text_center,
    } = skills || {};

    return <ThemeProvider theme={light} colors={light}>
        <Header headerStyle="light"/>
        <div className="JobHero">
            <div className="container" ref={ref}>
                <div className="row">
                    <div className="col-12 mb-5">
                        <Fade bottom delay={400}>
                            <Link to="/jobs/" className="back-button" replace>
                                <ArrowIcon/>
                                <FormattedMessage id="jobs.back"/>
                            </Link>
                        </Fade>
                    </div>
                </div>
                <div className="row">

                        <div className="col-12">
                            <Fade bottom delay={600}>
                                <Dash/>
                            </Fade>
                            <Fade bottom delay={650}>
                                <Heading level={'1'}>
                                    <FormattedMessage id={title}/>
                                </Heading>
                            </Fade>
                        </div>
                        <div className="col-lg-6 col-md-8 col-12">
                            <Fade bottom delay={650}>
                                <div className="skills">
                                    <div>
                                        <span><FormattedMessage id={experience_title}/></span>
                                        <span><FormattedMessage id={experience}/></span>
                                    </div>
                                    <div>
                                        <span><FormattedMessage id={employment_title}/></span>
                                        <span><FormattedMessage id={employment}/></span>
                                    </div>
                                    <div>
                                        <span><FormattedMessage id={placeofwork_title}/></span>
                                        <span><FormattedMessage id={placeofwork}/></span>
                                    </div>
                                </div>
                            </Fade>
                    </div>
                    <div className="row mt-5 flex-wrap-reverse px-2">
                        <div className="col-lg-6 col-12">
                            <Fade bottom delay={800}>
                                <Heading level={'4'}>
                                    <FormattedMessage id={title_left}/>
                                </Heading>
                                <Paragraph customColor={'blueDarken100'}>
                                    <FormattedMessage id={text_left}/>
                                </Paragraph>
                                <Paragraph customColor={'blueDarken100'}>
                                    <FormattedMessage id={text_center}/>
                                </Paragraph>
                            </Fade>
                        </div>
                        <div className="col offset-lg-1 offset-0 drop-background mt-lg-0 mt-5 position-relative">
                            <Fade bottom delay={600}>
                                <div>
                                    <DropBg src={JobHeroDrop} noOverflow={true} zIndex={'-1'} top={'0'} right={'0'} hideOnLG={true}/>
                                    {/*<DropBg src={JobHeroDropS} zIndex={'-1'} top={'10px'} right={'10px'} hideOnMobile={true}/>*/}
                                    <div className="drop-paragraph">
                                        <Heading level={'4'}>
                                            <FormattedMessage id={title_right}/>
                                        </Heading>
                                        <Paragraph customColor={'blueDarken100'} customSize={17}>
                                            <FormattedMessage id={text_right}/>
                                        </Paragraph>
                                    </div>
                                </div>
                            </Fade>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ThemeProvider>
}


export default JobHero;
